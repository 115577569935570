<template>
  <div>
    <div class="section-header mb-n2">
      <h4 style="font-weight: 500">Ventes à crédit</h4> 
      <div>
        <mdb-btn
          class="float-right"
          tag="a"
          color="primary"
          icon="plus"
          size="sm"
          small
          @click="addOrEdit()"
          v-if="$gate.hasPermission(['create::credit-sale'])"
        >
        </mdb-btn>
      </div>
    </div>
    <hr class="" />
    
    <mdb-row>
      <mdb-col sm="12" md="12" lg="12" class="mb-4 mx-0 mx-auto">
        <mdb-card cascade>
          <mdb-view
            v-if="false"
            class="card-header-reversed pl-2 pt-1 pb-1 d-flex justify-content-between align-items-center"
            cascade
          >
            <div
              class="title-card-tec card-header-title text-primary color-primary-reversed"
              style="font-weight: 400"
            >
              Dépots
            </div>
            <mdb-btn
              class="float-right"
              tag="a"
              color="primary"
              icon="plus"
              size="sm"
              small
              @click="addOrEdit(null)"
            >
            </mdb-btn>
          </mdb-view>

          <mdb-card-body :class="'card-reversed'" cascade v-loading="loading">
            <div class="tool-sp">
              <div></div>
              <mdb-btn
                class="float-right"
                tag="a"
                color="primary"
                outlined
                size="sm"
                small
                @click="creditReport()"
                v-if="$gate.hasPermission(['view::credit-sale-report'])"
              >
              Exporter
              </mdb-btn>
            </div>
            <div class="tool-sp">
                <mdb-select
                  flipOnScroll
                  label="Nombre par page"
                  outline
                  class=""
                  @getValue="selected"
                  v-model="pagination.pagination.perPageOptions"
                />
                <mdb-input
                  inputClass="border-reversed color-normal-reversed"
                  labelClass=" color-normal-reversed"
                  icon="search"
                  outline
                  type="text"
                  v-model="searchQuery"
                  @change="search()"
                  placeholder="Rechercher"
                  aria-label="Search"
                />
            </div>
            

            <mdb-tbl responsiveMd >
              <mdb-tbl-head>
                <tr class="color-normal-reversed">
                  <th v-for="column in data.columns" :key="column.name">
                    {{ column.label }}
                  </th>
                </tr>
              </mdb-tbl-head>
              <mdb-tbl-body>
                <tr
                  class="tec-table-row color-normal-reversed"
                  v-for="(row,index) in queriedData"
                  :key="index"
                >
                <td style="font-weight:500" >
                  <span v-if="!row.validated_at">{{`...`+row.code_credit_sale.split('-')[row.code_credit_sale.split('-').length - 1]}}</span>
                  <span v-else>{{row.num_credit}}</span>  
                </td>
                <td style="font-weight:400" > {{row.customer.designation}} </td>
                <td style="font-weight:400" > {{row.article.designation}} </td>
                <td style="font-weight:400; text-align: right;" > {{row.quantity | formatNumber }} {{row.article.unit}} </td>
                <td style="font-weight:400; text-align: right;" > {{row.amount | formatNumber}} XOF</td>
                <td style="font-weight:400" > 
                  <span style="box-shadow:none" v-if="row.is_validated === 1" class="badge badge-primary pt-1">validé</span>
                  <span style="box-shadow:none" v-else class="badge badge-danger pt-1">enregistré</span>
                </td>
                  <td class="d-flex justify-content-end">
                    <el-dropdown trigger="click">
                      <span
                        class="el-dropdown-link"
                        style="cursor: pointer; font-weight: 500"
                      >
                        Actions<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>

                      <el-dropdown-menu class="dropdown-el-tec" slot="dropdown">
                        <el-dropdown-item
                          class="dropdown-item-el-tec"
                          icon="el-icon-s-order"
                          v-if="row.validated_at && $gate.hasPermission(['view::credit-sale'])"
                          @click.native="exportPdf(row)"
                          >Exporter PDF</el-dropdown-item
                        >
                        <el-dropdown-item
                          @click.native="addOrEdit(row)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-edit"
                          v-if="!row.validated_at && $gate.hasPermission(['edit::credit-sale'])"
                          >Modifier</el-dropdown-item
                        >
                        <el-dropdown-item
                          v-if="!row.validated_at && $gate.hasPermission(['delete::credit-sale'])"
                          @click.native="deleteCredit(row.id)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-delete"
                          >Supprimer</el-dropdown-item
                        >
                        <el-dropdown-item
                          v-if="row.validated_at && $gate.hasPermission(['cancel::credit-sale'])"
                          @click.native="cancelCredit(row.id)"
                          class="dropdown-item-el-tec"
                          icon="el-icon-delete"
                          >Annuler cette vente</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </td>
                </tr>
              </mdb-tbl-body>
            </mdb-tbl>

            <div v-if="empty" class="d-flex justify-content-center w-100 p-5">
              <img
                :src="require('../../../../assets/svg/empty_streec.svg')"
                class="empty"
                alt=""
                srcset=""
              />
            </div>

            <div class="d-flex justify-content-end">
              <tec-pagination
                v-model="pagination.pagination.currentPage"
                :per-page="parseFloat(pagination.pagination.perPage)"
                :total="pagination.pagination.total"
                v-on:changePageEvent="loadCreditBack"
              ></tec-pagination>
            </div>
          </mdb-card-body>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </div>
</template>

<script>
import {
  mdbCard,
  mdbCardBody,
  mdbView,
  mdbBtn,
  mdbTbl,
  mdbTblHead,
  mdbTblBody,
  mdbRow,
  mdbCol,
  mdbSelect,
  mdbInput,
} from "mdbvue";
import TecPagination from "@/tba/components/PaginationUrl";

export default {
  components: {
    mdbCard,
    mdbCardBody,
    mdbView,
    mdbBtn,
    mdbTbl,
    mdbTblHead,
    mdbTblBody,
    mdbRow,
    mdbCol,
    mdbSelect,
    mdbInput,
    TecPagination,
  },
  props: {
    empty: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Array,
      default: () => [],
    },
    pageData: {
      type: Array,
      default: () => [],
    },
    queriedData: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  methods: {
    exportPdf(e) {
      this.$emit("export_pdf", e);
    },

    addOrEdit(data = null) {
      this.$emit("add_or_edit", data);
    },

    creditReport(e) {
      this.$emit("credit_report", e);
    },

    selected(e) {
      this.$emit("per_page_selected", e);
    },

    deleteCredit(id) {
      this.$emit("delete_credit", id);
    },

    cancelCredit(id) {
      this.$emit("cancel_credit", id);
    },

    nbrPerPageSelected(value) {
      this.$emit("per_page_selected", value);
    },
    loadCreditBack(e) {
      this.$emit("load_credit_back", {
        page: e,
        perPage: this.pagination.pagination.perPage,
      });
    },
    search() {
      this.$emit("search_q", this.searchQuery);
    },
    },
};
</script>
