<template>
    <div class="container-fluid">
        <list-desktop
            v-if="!$isMobile()"
            :empty="empty"
            :loading="loading"
            :pagination="getP"
            :queriedData="queriedData"
            :data="data"
            :total="total"
            v-on:load_credit_back="loadCreditSaleBack"
            v-on:search_q="searchBack"
            v-on:per_page_selected="nbrPerPageSelected"
            v-on:add_or_edit="addOrEdit"
            v-on:load_credits="loadCreditSales"
            v-on:delete_credit="deleteCreditSale"
            v-on:cancel_credit="openModalCancel"
            v-on:export_pdf="exportPdf"
            v-on:credit_report="creditReport"
        >
        </list-desktop>

        <list-mobile
            v-if="$isMobile()"
            :empty="empty"
            :loading="loading"
            :pagination="getP"
            :queriedData="queriedData"
            :data="data"
            :total="total"
            v-on:add_or_edit="addOrEdit"
            v-on:search_q="searchBack"
            v-on:load_credits="loadCreditSales"
            v-on:delete_credit="deleteCreditSale"
            v-on:cancel_credit="openModalCancel"
            v-on:per_page_selected="nbrPerPageSelected"
            v-on:export_pdf="exportPdf"
            v-on:credit_report="creditReport"
            v-on:load_credit_back="loadCreditSaleBack"
        >
        </list-mobile>
     

    <mdb-modal
        centered
        size="lg" 
        direction="top"
        position="top"
        :show="modalCreditSale.show" 
        @close="modalCreditSale.show = false" 
    >
        <form @submit.prevent='modalCreditSale.edit ? update() : save()'>
        <mdb-modal-header>
            <mdb-modal-title>{{ modalCreditSale.title }}</mdb-modal-title>
        </mdb-modal-header>
        <mdb-modal-body >
            <fieldset>
                <mdb-row>
                    
                    <mdb-col sm="12" md="6" lg="6">
                        <mdb-select
                            flipOnScroll
                            search
                            v-model="articleOption"
                            @getValue="getArticle"
                            outline
                            label="Article"
                        ></mdb-select>
                        <div
                            style="color: #dc3545; font-size: 0.8rem" class="mt-n3"
                            v-if="$store.state.credit_sale.errors.article_id"
                            >
                            {{ $store.state.credit_sale.errors.article_id }}
                        </div>
                    </mdb-col>
                    <mdb-col sm="12" md="6">
                        <mdb-input
                            :disabled="!hasSetdateAndArticle"
                            :validation="$store.state.credit_sale.errors.matriculation ? true : false"
                            :invalidFeedback="$store.state.credit_sale.errors.matriculation"
                            v-model="form.matriculation"
                            @change="matriculationUpper"
                            size="md" outline label="Numero Immatriculation" ></mdb-input>
                    </mdb-col>
                    <mdb-col sm="12" md="6"  class="mt-n2" lg="6">
                        <mdb-select
                            flipOnScroll
                            search
                            v-model="customerOption"
                            @getValue="getCustomer"
                            outline
                            label="Client"
                            :disabled="!hasSetdateAndArticle"
                        ></mdb-select>
                        <div
                            style="color: #dc3545; font-size: 0.8rem" class="mt-n3"
                            v-if="$store.state.credit_sale.errors.customer_id"
                            >
                            {{ $store.state.credit_sale.errors.customer_id }}
                        </div>
                        <div>
                            <table class="table" v-loading="loadingCreditPld">
                                <tr>
                                    <td>Plafond de credit</td>
                                    <td>{{credit_plafond | formatNumber}} XOF</td>
                                </tr>
                                <tr>
                                    <td>Impayée</td>
                                    <td>{{credit_no_paid | formatNumber}} XOF</td>
                                </tr>
                            </table>
                        </div>
                    </mdb-col>
                     
                    <mdb-col sm="12" md="6" class="mt-n2">
                        <mdb-input
                        :disabled="!hasSetdateAndArticle"
                            :validation="$store.state.credit_sale.errors.amount ? true : false"
                            :invalidFeedback="$store.state.credit_sale.errors.amount"
                            type="number"
                            v-model="form.amount"
                            @change="verifyPlafondCredit"
                            size="md" outline label="Montant" ></mdb-input>
                            <div>
                            <table class="table">
                                <tr>
                                    <td>Quantité</td>
                                    <td style="text-align:right">{{ this.form.quantity | formatNumber }} {{this.form.article_unit}}</td>
                                </tr>
                            </table>
                        </div>
                    </mdb-col>

                    

                    <mdb-col sm="12" md="12">
                        <div class="custom-control custom-switch">
                            <input type="checkbox" class="custom-control-input" id="customSwitches" v-model="form.is_validated">
                            <label class="custom-control-label" for="customSwitches">Valider la vente</label>
                            <p class="avertissemendebt">
                                <span>En cochant ce bouton, vous stipulez correcte les informations fournies.</span> <br>
                                <span>Ces informations seront envoyées telles qu'elles au client.</span> <br>
                                <span style="color:red">NB : Aucune modification ne sera possible, une fois coché.</span>
                            </p>
                        </div>
                    </mdb-col>
                    
                </mdb-row>
            </fieldset>
        </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" color="primary" type="submit" :disabled="modalCreditSale.btn">
                    <span v-if="!modalCreditSale.btn">Sauvegarder</span>
                    <span v-if="modalCreditSale.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalCreditSale.btn" class="pl-2">Loading...</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalCreditSale.show = false">Fermer</mdb-btn>

            </mdb-modal-footer>
        </form>
    </mdb-modal>

    <!-- Exports -->
    <mdb-modal
        size="md" 
        direction="top"
        position="top"
        :show="modalDeptReport.show"
        @close="modalDeptReport.show =false "
    > 
        <form @submit.prevent="downloadReport()">
        <mdb-modal-header>
            <mdb-modal-title>{{ modalDeptReport.title }}</mdb-modal-title><br>
        </mdb-modal-header>
        <mdb-modal-body class="">
            <mdb-row>
                <mdb-col sm="12" md="12">
                        <mdb-select
                            flipOnScroll
                            v-model="typeReportOption"
                            @getValue="getTypeReportDebtReport"
                            outline
                            label="Type de rapport"
                        ></mdb-select>
                    </mdb-col>
                <mdb-col sm="12" md="6">
                        <mdb-date-picker-2
                            :options="option"
                            v-mask="'####-##-##'"
                            autoHide
                            outline
                            v-model="formCreditSaleReport.from"
                            :defaultDate="formCreditSaleReport.from"
                            label="Date début"
                            title="Date début"
                        />
                    </mdb-col>
                    <mdb-col sm="12" md="6">
                        <mdb-date-picker-2
                            :options="option"
                            v-mask="'####-##-##'"
                            autoHide
                            outline
                            v-model="formCreditSaleReport.to"
                            :defaultDate="formCreditSaleReport.to"
                            label="Date fin"
                            title="Date fin"
                        />
                    </mdb-col>
                    <mdb-col sm="12" md="6" v-if="formCreditSaleReport.typeReport === 'detailed'">
                        <mdb-select
                            flipOnScroll
                            search
                            multiple
                            selectAll
                            v-model="customerOption"
                            @getValue="getCustomerDebtReport"
                            outline
                            label="Clients"
                        ></mdb-select>
                    </mdb-col>
                    <mdb-col sm="12" md="6" v-if="$gate.checkTypeRole(['super-admin', 'admin'])">
                        <mdb-select
                            flipOnScroll
                            search
                            multiple
                            selectAll
                            v-model="userOption"
                            @getValue="getUserDebtReport"
                            outline
                            label="Utilisateurs"
                        ></mdb-select>
                    </mdb-col>
                    <mdb-col sm="12" md="6" v-if="$gate.checkTypeRole(['super-admin', 'admin'])">
                        <mdb-select
                            flipOnScroll
                            search
                            multiple
                            selectAll
                            v-model="siteOption"
                            @getValue="getSiteDebtReport"
                            outline
                            label="Sites"
                        ></mdb-select>
                    </mdb-col>
                    <mdb-col sm="12" md="6">
                        <mdb-select
                            flipOnScroll
                            v-model="formatOption"
                            @getValue="getFormatDebtReport"
                            outline
                            label="Format d'export"
                        ></mdb-select>
                    </mdb-col>
            </mdb-row>
        </mdb-modal-body>
        <mdb-modal-footer class="">
            <mdb-btn size="md" color="primary" type="submit"  :disabled="modalDeptReport.btn" >
                <span v-if="!modalDeptReport.btn">Exporter le rapport</span>
                <span v-if="modalDeptReport.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <span v-if="modalDeptReport.btn" class="pl-2">Loading...</span>
            </mdb-btn>
            <mdb-btn size="md" outline="primary" @click="modalDeptReport.show = false">Fermer</mdb-btn>

        </mdb-modal-footer>
        </form>
    </mdb-modal>

    <mdb-modal
        size="md" 
        direction="top"
        position="top"
        :show="modalCancelCredit.show"
        @close="modalCancelCredit.show =false "
    > 
        <form @submit.prevent="cancelCreditSale">
            <mdb-modal-header>
                <mdb-modal-title>{{ modalCancelCredit.title }}</mdb-modal-title><br>
            </mdb-modal-header>
            <mdb-modal-body class="">
                <mdb-row>
                    <mdb-col sm="12" md="12">
                        <mdb-textarea v-model="modalCancelCredit.content.motif" outline placeholder="Renseignez le motif de l'annulation" label="Motif de l'annulation" :rows="5" />
                    </mdb-col>
                </mdb-row>
            </mdb-modal-body>
            <mdb-modal-footer class="">
                <mdb-btn size="md" color="primary" type="submit"  :disabled="modalCancelCredit.btn" >
                    <span v-if="!modalCancelCredit.btn">Valider</span>
                    <span v-if="modalCancelCredit.btn" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    <span v-if="modalCancelCredit.btn" class="pl-2">Loading...</span>
                </mdb-btn>
                <mdb-btn size="md" outline="primary" @click="modalCancelCredit.show = false">Fermer</mdb-btn>
            </mdb-modal-footer>
        </form>
    </mdb-modal>
    </div>
</template>

<script>
import ListDesktop from './modules/ListDesktop'
import ListMobile from './modules/ListMobile'
import download from "../../../services/download-fetch"

import {
    mdbBtn,
    mdbRow,mdbCol,
    mdbInput,
    mdbSelect,
    mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
    mdbDatePicker2,
    mdbTextarea
    // mdbSwitch,
} from 'mdbvue'
export default {
    name:"creditSales",
    metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - Ventes a credit",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
    components:{
        mdbBtn,
        mdbRow,mdbCol,
        mdbInput,
        mdbModal,mdbModalTitle,mdbModalBody,mdbModalHeader,mdbModalFooter,
        mdbDatePicker2,
        mdbSelect,
        mdbTextarea,
        // mdbSwitch,
        ListDesktop,ListMobile
    },

    data(){
        return {
            option: {
                week: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
                month: [
                    "Janvier",
                    "Février",
                    "Mars",
                    "Avril",
                    "Mai",
                    "Juin",
                    "Juillet",
                    "Août",
                    "Septembre",
                    "Octobre",
                    "Novembre",
                    "Décembre",
                ],
                placeholder: "Choisir une date",
                buttons: {
                    ok: "Ok",
                    clear: "Effacer",
                    cancel: "annuler",
                    today: "Aujourd hui",
                },
            },
            loading:false,
            loadingCreditPld:false,
            dataPagination: {
                pagination: {
                    perPage: 10,
                    currentPage: 1,
                    perPageOptions: [
                        {text:"10",value:"10", selected: true},
                        {text:"25",value:"25"},
                        {text:"50",value:"50"},
                        {text:"100",value:"100"},
                    ],
                    total: 0,
                },
                searchQuery: "",
                propsToSearch: [
                    "start_date",
                    "end_date",
                    "amount",
                    "customer",
                ],
            },

            formatOption: [
                {text:"XLSX",value:"xlsx"},
                {text:"CSV",value:"csv"},
                {text:"PDF",value:"pdf", selected: true},
            ],

            typeReportOption: [
                {text:"Rapport détaillé",value:"detailed", selected: true},
                {text:"Rapport combiné",value:"combined"},
            ],

            empty:false,
            data:{
                columns: [
                    {
                        label: "Code",
                        field: "code_credit_sale",
                        sort: true,
                    },
                    {
                        label: "Client",
                        field: "customer",
                        sort: true,
                    },
                    {
                        label: "Article",
                        field: "article",
                        sort: true,
                    },
                    {
                        label: "Quantité",
                        field: "quantity",
                        sort: true,
                    },
                    {
                        label: "Montant",
                        field: "amount",
                        sort: true,
                    },
                    {
                        label: "Status",
                        field: "status",
                        sort: true,
                    },
                    {
                        label: "",
                        field: "action",
                        sort: true,
                    },
                ],
                credit_sale: {
                    current_page: 1,
                    data: [],
                },
            },

            credit_no_paid:0,
            credit_plafond:0,

            customers: [],
            customerOption: [],

            users: [],
            userOption: [],

            sites: [],
            siteOption: [],

            articles: [],
            articleOption: [],

            form:{
                id:"",
                created_at: this.$moment().format('YYYY-MM-DD'),
                quantity:0,
                amount:0,
                customer_id:'',
                price: {},
                article_id:'',
                article_unit:'',
                matriculation:'',
                is_validated:false
            },

            formCreditSaleReport:{
                from:this.$moment().format("YYYY-MM-DD"),
                to:this.$moment().format("YYYY-MM-DD"),
                format:"pdf",
                typeReport:"detailed",
                customers:[],
                articles:[],
                users:[],
                sites:[],
            },

            modalCreditSaleDetails: {
                show: false,
                edit: false,
                title: "Détails  vente à crédit",
                content: {},
            },
            modalCreditSale: {
                show: false,
                edit: false,
                title: "Ajouter une vente à crédit",
                content: {},
                btn:false
            },
            modalDeptReport: {
                show: false,
                edit: false,
                title: "Exporter des rapports",
                content: {},
                btn:false
            },
            modalCancelCredit: {
                show: false,
                edit: false,
                title: "Annuler la vente",
                content: {
                    motif:'',
                    id:'',
                },
                btn:false
            },
        }
    },

    methods:{
        //Fonction de fonctionnement
        addOrEdit(data = null) {
            if (data instanceof Object) {
                this.modalCreditSale.title = "Modifier une vente à crédit";
                this.modalCreditSale.content = data;
                this.modalCreditSale.edit = true;
                this.formRest(data);
            } else {
                this.modalCreditSale.title = "Ajouter une vente à crédit";
                this.modalCreditSale.content = {};
                this.modalCreditSale.edit = false;
                this.cleanForm("credit_sale");
            }
            this.$store.commit('credit_sale/SET_AMOUNT', [""])
            this.modalCreditSale.show = true;
        }, 

        cleanForm(type) {
            if (type === 'credit_sale') {
				this.form.id = ""
                this.form.created_at = this.$moment().format('YYYY-MM-DD')
                this.form.matriculation = ""
                this.form.article_id = ""
                this.form.article_unit = ""
                this.form.customer_id = ""
                this.form.quantity = 0
                this.form.amount = 0
                this.form.amount = {}
                this.form.is_validated = false

                this.credit_no_paid = 0
                this.credit_plafond = 0

                this.customerOption.forEach((el) => {
                    el.selected = false
                });

                this.articleOption.forEach((el) => {
                    el.selected = false
                });
			}
        },

        formRest(data) {
            this.form.id = data.id
            this.form.created_at = data.created_at
            this.form.matriculation = data.matriculation
            this.form.customer_id = data.customer.id
            this.form.article_id = data.article.id
            this.form.amount = data.amount
            this.form.quantity = data.quantity
            this.form.is_validated = data.is_validated
            this.form.price = {}
            this.form.article_unit = ""

            this.credit_no_paid = 0
            this.credit_plafond = 0

            this.getPrice(this.form.article_id)

            this.getCreditPlafond(this.form.customer_id).then(() => {
                // this.credit_no_paid = this.credit_no_paid - this.form.amount
            })

            this.customerOption.forEach((el) => {
                 if(el.value === data.customer.id){
                    el.selected = true
                 }else{
                    el.selected = false
                 }
            });

            this.articleOption.forEach((el) => {
                 if(el.value === data.article.id){
                    el.selected = true
                    this.form.article_unit = el.unit

                 }else{
                    el.selected = false
                 }
            });
        },

        matriculationUpper(){
            this.form.matriculation = this.form.matriculation.toUpperCase()
        },

        dateChange() {
            // this.$notify({
            // message:
            //     "Le changement de date de la facture reinitialise la liste des articles.",
            // type: "info",
            // });
            if (this.form.article_id && this.form.created_at) {
                this.getPrice(this.form.article_id)
            }
        },

        verifyPlafondCredit()
        {
            let marge = this.credit_plafond - this.credit_no_paid;
            if (parseFloat(this.form.amount) > parseFloat(marge) ) {
                this.$store.commit('credit_sale/SET_AMOUNT', [`Encours dépassé, reste à consommer ${new Intl.NumberFormat('bj-BJ').format(marge)} XOF`])
            }else{
                this.$store.commit('credit_sale/SET_AMOUNT', [""])

                // Calculer la quantité
                this.form.quantity = parseFloat(this.form.amount/this.form.price.ttc)
            }


        },

        async getCreditPlafond (customer) {
			this.$nprogress.start()
            this.loadingCreditPld = !this.loadingCreditPld

            this.credit_no_paid = 0
            this.credit_plafond = 0
                
            await this.$store.dispatch('customer/getCreditPlafond',customer)
            .then(response => {
                this.$nprogress.done()
                this.loadingCreditPld = !this.loadingCreditPld
                this.credit_no_paid = response.data.credit_no_paid
                this.credit_plafond = response.data.credit_plafond
            }).catch((error) => {
				this.$nprogress.done()
                this.loadingCreditPld = !this.loadingCreditPld
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        getPrice(article_id) {
            this.articles.forEach((article) => {
                if (article.id === article_id) {
                    if (article.specific === 1) {
                        if (!this.modalCreditSale.edit) {
                            this.form.quantity = 0;
                            this.form.amount = 0;
                        }
                        
                        let $date = this.form.created_at.split("-");
                        let priceMonth = $date[0] + "/" + $date[1];
                        let code =
                        this.$store.state.auth.company.id +
                        "/" +
                        priceMonth +
                        "/" +
                        article_id;

                        this.$store
                        .dispatch("price/findByCodePrice", {
                            code: code,
                            article_id: article_id,
                        })
                        .then((response) => {
                            if (response.data.price === null) {
                                this.$notify({
                                    message:
                                    "Le prix de l'article n'est pas/plus référencé dans la structure de prix. Contactez l'administrateur.",
                                    type: "danger",
                                });
                                return;
                            }
                            this.form.price = response.data.price;
                        })
                        .catch((error) => {
                            this.$notify({
                            message: error.response.data.message || error.response.data.error,
                            type: "danger",
                            });
                        });
                    } else{
                        this.form.price = {};
                        this.$notify({
                            message: 'Cet article n\'a pas de structure de prix.',
                            type: "danger",
                        });
                    }
                }
            });
        },

        async loadCreditSales ({ page, perPage }) {
			this.$nprogress.start()
            this.loading = !this.loading
            await this.$store.dispatch('credit_sale/findAll', {
                page: page,
                perPage: perPage,
            })
            .then(response => {
                this.$nprogress.done()
                this.loading = !this.loading
                if (response.data.data.data.length > 0) {
                    this.empty = false;
                } else {
                    this.empty = true;
                }
                this.data.rows = response.data.data
                this.data.credit_sale = response.data.data;
                this.dataPagination.pagination.currentPage =
                    response.data.data.current_page;
                this.dataPagination.pagination.perPage = response.data.data.per_page;
                this.dataPagination.pagination.total = response.data.data.total;
            }).catch((error) => {
				this.$nprogress.done()
                this.loading = !this.loading
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
            })
        },

        async save() {
            this.$store.commit('credit_sale/SET_CLEAN')
            this.modalCreditSale.btn = !this.modalCreditSale.btn

            await this.$store.dispatch('credit_sale/save', this.form)
			.then((response) => {
				this.modalCreditSale.show = false
                this.modalCreditSale.btn = !this.modalCreditSale.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('debt_repayment')
                this.loadCreditSales({
                    page: 1,
                    perPage: this.dataPagination.pagination.perPage,
                })
			})
			.catch((error) => {
                this.modalCreditSale.btn = !this.modalCreditSale.btn
                if (error.response.data.errors) {
                    this.$store.commit('credit_sale/SET_OD', error.response.data.errors.created_at)
                    this.$store.commit('credit_sale/SET_AMOUNT', error.response.data.errors.amount)
                    this.$store.commit('credit_sale/SET_QTE', error.response.data.errors.quantity)
                    this.$store.commit('credit_sale/SET_CI', error.response.data.errors.customer_id)
                    this.$store.commit('credit_sale/SET_AI', error.response.data.errors.article_id)
                    this.$store.commit('credit_sale/SET_MATRICULATION', error.response.data.errors.matriculation)
                }

                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			}) 
        },

        async update() {
            this.$store.commit('credit_sale/SET_CLEAN')
            this.modalCreditSale.btn = !this.modalCreditSale.btn
			await this.$store.dispatch('credit_sale/update', this.form)
			.then((response) => {
				this.modalCreditSale.show = false
                this.modalCreditSale.btn = !this.modalCreditSale.btn
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
				this.cleanForm('credit_sale')
                this.loadCreditSales({
                    page:  this.data.credit_sale.current_page,
                    perPage: this.dataPagination.pagination.perPage,
                })
			})
			.catch((error) => {
                this.modalCreditSale.btn = !this.modalCreditSale.btn
                if (error.response.data.errors) {
                    this.$store.commit('credit_sale/SET_OD', error.response.data.errors.created_at)
                    this.$store.commit('credit_sale/SET_AMOUNT', error.response.data.errors.amount)
                    this.$store.commit('credit_sale/SET_QTE', error.response.data.errors.quantity)
                    this.$store.commit('credit_sale/SET_CI', error.response.data.errors.customer_id)
                    this.$store.commit('credit_sale/SET_AI', error.response.data.errors.article_id)
                    this.$store.commit('credit_sale/SET_MATRICULATION', error.response.data.errors.matriculation)
                }
                this.$notify({
                    message: error.response.data.message  || error.response.data.error,
                    type: 'danger'
                })
			})  
        },

        async deleteCreditSale(id){

            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
			await this.$store.dispatch('credit_sale/delete',id)
                .then((response) => {
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
                    this.loadCreditSales({
                        page: this.data.credit_sale.current_page,
                        perPage: this.dataPagination.pagination.perPage,
                    })  
                })
                .catch(error =>{
                    this.$notify({
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })
			
		},

        async cancelCreditSale(){
            if (!this.modalCancelCredit.content.id) {
                return
            }
            let res = await this.$store.dispatch("swal/doYouDelete");
            if (res.isDismissed) {
                return;
            }
            await this.$store.dispatch('credit_sale/cancel',{id: this.modalCancelCredit.content.id, motif: this.modalCancelCredit.content.motif})
                .then((response) => {
                    this.$notify({
                        message: response.data.message,
                        type: 'success'
                    })
                    this.modalCancelCredit.show = false
                    this.modalCancelCredit.content.id = ''
                    this.modalCancelCredit.content.motif = ''

                    this.loadCreditSales({
                        page: this.data.credit_sale.current_page,
                        perPage: this.dataPagination.pagination.perPage,
                    })  
                })
                .catch(error =>{
                    this.$notify({
                        message: error.response.data.message  || error.response.data.error,
                        type: 'danger'
                    })
                })

        },

        getCustomer(value) {
            this.form.customer_id = value;

            this.getCreditPlafond(value)
        },
        getArticle(value) {
            this.form.article_id = value;

            this.form.article_id = value;

            this.articleOption.forEach((el) => {
                if(el.value === value){
                this.form.article_unit = el.unit
                }
            });

            this.getPrice(value)
        },
        getCustomerDebtReport(value){
            this.formCreditSaleReport.customers = value
        },
        getTypeReportDebtReport(value){
            this.formCreditSaleReport.typeReport = value

            this.$nextTick(() => {
                this.formCreditSaleReport.customers = []
                this.customerOption.forEach(cust => {
                    cust.selected = false
                });

                if(value === 'combined'){
                    this.formatOption.push({
                        text:'Thermique',
                        value:'thermal'
                    })
                }
                if(value === 'detailed'){
                    let input = null;
                    this.formatOption.forEach(type => {
                        if (type.value === 'thermal') {
                            input = this.formatOption.indexOf(type)
                        }
                    })

                    if(input >= 0){
                        this.formatOption.splice(input,1)
                    }

                }
            })
        },
        getUserDebtReport(value){
            this.formCreditSaleReport.users = value
        },

        getSiteDebtReport(value){
            this.formCreditSaleReport.sites = value
        },
        getFormatDebtReport(value){
            this.formCreditSaleReport.format = value
        },

        async loadCustomers() {
            this.customerOption = [];
            this.$nprogress.start();
            await this.$store
            .dispatch("customer/findAll")
            .then((response) => {
                this.$nprogress.done();
                this.customers = response.data.customers.filter(
                    (customer) => customer.is_active === 1 && customer.approuved === 1 
                ).filter((customer) => customer.designation.toLowerCase() !== 'divers');
                this.customers.forEach((customer) => {
                    this.customerOption.push({
                    text: customer.designation,
                    value: customer.id,
                    });
                });
            })
            .catch((error) => {
                this.$nprogress.done();
                this.$notify({
                    message: error.response.data.message || error.response.data.error,
                    type: "danger",
                });
            });
        },

        async loadArticles() {
            this.articleOption = [];
            this.$nprogress.start();
            await this.$store
                .dispatch("article/findAllOnlySpecific")
                .then((response) => {
                    this.$nprogress.done();
                    this.articles = response.data.articles.filter(
                        (article) => article.is_active === 1
                    );
                    this.articles.forEach((article) => {
                        this.articleOption.push({
                            text: article.ref + " - " + article.designation,
                            value: article.id,
                            unit: article.unit,
                        });
                    });
                })
                .catch((error) => {
                    this.$nprogress.done();
                    this.$notify({
                        message: error.response.data.message || error.response.data.error,
                        type: "danger",
                    });
                });
        },

        async loadSites() {
            if (!this.$gate.checkTypeRole(["super-admin", "admin"])) {
                return (this.sites = []);
            }
            await this.$store
            .dispatch("site/findAll")
            .then((response) => {
                this.sites = response.data.sites;

                this.sites.forEach((site) => {
                    this.siteOption.push({
                        text: site.name,
                        value: site.id,
                    });
                });
            })
            .catch((error) => {
                this.$notify({
                    message: error.response.data.message || error.response.data.error,
                    type: "danger",
                });
            });
        },

        async loadUsers() {
            if (!this.$gate.checkTypeRole(["super-admin", "admin"])) {
                return (this.users = []);
            }
            this.$nprogress.start();
            await this.$store
                .dispatch("user/findAll")
                .then((response) => {
                this.users = response.data.users;
                this.users.forEach((user) => {
                    this.userOption.push({
                        text: user.lastname + " " + user.firstname,
                        value: user.id,
                    });
                });
                })
                .catch((error) => {
                this.$nprogress.done();
                this.$notify({
                    message: error.response.data.message || error.response.data.error,
                    type: "danger",
                });
            });
        },

        async exportPdf(row) {
            let th = 0;
            let doyouPrint = await this.$store.dispatch("swal/doYouPrintPdf");

            if (doyouPrint.isConfirmed) {
                th = 0;
            } else if (doyouPrint.isDenied) {
                th = 1;
            } else {
                return;
            }

            this.$nprogress.start();
            await download({
                url: `/credit-sales/${row.id}?th=${th}&r=1`,
                isTh: th,
                filename: this.$moment(row.created_at).format('Y-MM-DD') + ".pdf",
            })
            .then(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "PDF Généré avec succès",
                    type: "success",
                });
                })
                .catch(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "Echec durant l'exportation, reessayer ulterieurement",
                    type: "danger",
                });
            });
        },

        async downloadReport(){
            this.$nprogress.start()

            if (!this.$gate.checkTypeRole(["super-admin","admin"])) {
                this.formCreditSaleReport.users = []
                this.formCreditSaleReport.sites = []

                this.userOption.forEach(cust => {
                    cust.selected = false
                });
                this.siteOption.forEach(cust => {
                    cust.selected = false
                });
            }
            await download({
                isTh:this.formCreditSaleReport.format === 'thermal'? 1: 0,
                method:"post",
                data:this.formCreditSaleReport,
                url:"/reports/credit-sales-"+this.formCreditSaleReport.typeReport+"?export="+this.formCreditSaleReport.format,
                filename:"credit_sales_"+this.formCreditSaleReport.typeReport+"."+this.formCreditSaleReport.format
            }).then(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "Téléchargé avec succès",
                    type: 'success'
                })
                this.modalDeptReport.show = false
            })
            .catch(() => {
                this.$nprogress.done();
                this.$notify({
                    message: "Echec durant le téléchargement",
                    type: 'danger'
                })
            })
        },
         
        creditReport(){
            this.modalDeptReport.show = true
        },

        loadCreditSaleBack(e) {
        this.loadCreditSales({
            page: e.page,
            perPage: e.perPage,
        });
        },
        searchBack(e) {
        this.dataPagination.searchQuery = e;
        },

        // Systeme de datatable
        totalCalcul() {
        return (this.dataPagination.pagination.total = this.data.credit_sale.total);
        },

        searchQ() {
        if (!this.dataPagination.searchQuery) {
            // return this.pagedData;
            return new Error("Nothing To Search");
        }

        let result = this.data.credit_sale.data.filter((row) => {
            let isIncluded = false;
            for (let key of this.dataPagination.propsToSearch) {
                let rowValue = "";
                rowValue = `${row[key]}`.toString().toLowerCase();
                if (rowValue.includes && rowValue.includes(this.dataPagination.searchQuery.toLowerCase())) {
                isIncluded = true;
                }
            }
            return isIncluded;
        });
        this.dataPagination.pagination.total = result.length;
        return result.slice(this.from, this.to);
        },

        nbrPerPageSelected(e) {
        let nbr = e;
        return this.nbrPerPage(nbr);
        },

        nbrPerPage(nbr) {
        this.loadCreditSales({
            page: 1,
            perPage: nbr,
        });
        return (this.dataPagination.pagination.perPage = nbr);
        },
        setTotal() {
        if (this.dataPagination.searchQuery === "") {
            this.dataPagination.pagination.total = this.data.credit_sale.total;
        }
        },

        designationFormatter(){
            this.form.designation = this.form.designation.toUpperCase()
        },
        openModalCancel(id){
            this.modalCancelCredit.content.motif = ''
            this.modalCancelCredit.content.id = id
            this.modalCancelCredit.show = true
        }
    },

    created() {
        this.loadCreditSales({
            page: this.data.credit_sale.current_page,
            perPage: this.dataPagination.pagination.perPage,
        })  
        this.loadCustomers()  
        this.loadUsers()
        this.loadSites()
        this.loadArticles()
    },

    computed: {

        hasSetdateAndArticle() {
            return this.form.created_at && this.form.article_id
        },

        // getUnitArticle() {
        //     let unit = ""
        //     array.forEach(element => {
                
        //     });
        //     return unit
        // },

        pageData() {
            return this.data.credit_sale.data;
        },

        
        to() {
            let highBound = this.from + this.dataPagination.pagination.perPage;
            if (this.data.credit_sale.total < parseInt(highBound)) {
                highBound = this.total;
            }
            return parseInt(highBound);
        },
        from() {
        return (
            this.dataPagination.pagination.perPage *
            (this.dataPagination.pagination.currentPage - 1)
        );
        },

        queriedData() {
        let result = this.searchQ();
        this.setTotal();
        return result instanceof Error ? this.pageData : result;
        },

        total() {
        return this.totalCalcul();
        },

        getP() {
        return this.dataPagination;
        },
    }
}
</script>

<style lang="scss" >
.avertissemendebt{
    font-size: .8em;
}
</style>